<template><div></div></template>

<script>
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'Fonts'
}
</script>

<style>
@font-face {
    font-family: 'DIN Pro';
    src: url('https://media.skin.fans/m/root/fonts/DINPro-Medium.eot');
    src: local('DIN Pro Medium'), local('DINPro-Medium'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Medium.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Medium.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('https://media.skin.fans/m/root/fonts/DINPro-Bold.eot');
    src: local('DIN Pro Bold'), local('DINPro-Bold'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Bold.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro';
    src: url('https://media.skin.fans/m/root/fonts/DINPro.eot');
    src: local('DIN Pro'), local('DINPro'),
    url('https://media.skin.fans/m/root/fonts/DINPro.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('https://media.skin.fans/m/root/fonts/DINPro-CondensedRegular.eot');
    src: local('DIN Pro Condensed Regular'), local('DINPro-CondensedRegular'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedRegular.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedRegular.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedRegular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('https://media.skin.fans/m/root/fonts/DINPro-CondensedMedium.eot');
    src: local('DIN Pro Condensed Medium'), local('DINPro-CondensedMedium'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedMedium.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedMedium.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedMedium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN Pro Cond';
    src: url('https://media.skin.fans/m/root/fonts/DINPro-CondensedBold.eot');
    src: local('DIN Pro Condensed Bold'), local('DINPro-CondensedBold'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedBold.eot?#iefix') format('embedded-opentype'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedBold.woff') format('woff'),
    url('https://media.skin.fans/m/root/fonts/DINPro-CondensedBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

.fdp-default {
    font-family: 'DIN Pro';
    font-weight: normal;
}

.fdp-medium {
    font-family: 'DIN Pro';
    font-weight: 500;
}

.fdp-bold {
    font-family: 'DIN Pro';
    font-weight: bold;
}

.fdpc-default {
    font-family: 'DIN Pro Cond';
    font-weight: normal;
}
.fdpc-bold {
    font-family: 'DIN Pro Cond';
    font-weight: bold;
}

.fdpc-medium {
    font-family: 'DIN Pro Cond';
    font-weight: 500;
}

.fdpc-black {
    font-family: 'DIN Pro Cond';
    font-weight: 900;
}
</style>