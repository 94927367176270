export const FRAGMENT_SHADER_HOLO = `

    #define STANDARD
precision highp int;
precision highp float;

#ifdef PHYSICAL
#define REFLECTIVITY
#define CLEARCOAT
#define TRANSPARENCY
#endif

uniform vec3 diffuse;
uniform vec3 emissive;
uniform float roughness;
uniform float metalness;
uniform float opacity;

#ifdef TRANSPARENCY
uniform float transparency;
#endif

#ifdef REFLECTIVITY
uniform float reflectivity;
#endif

#ifdef CLEARCOAT
uniform float clearcoat;
uniform float clearcoatRoughness;
#endif

#ifdef USE_SHEEN
uniform vec3 sheen;
#endif

varying vec3 vViewPosition;

#ifndef FLAT_SHADED

varying vec3 vNormal;

#ifdef USE_TANGENT

varying vec3 vTangent;
varying vec3 vBitangent;

#endif

#endif

#include <common>
#include <packing>
#include <dithering_pars_fragment>
#include <color_pars_fragment>
#include <uv_pars_fragment>
#include <uv2_pars_fragment>
// ─────────────────────────────────────────────────────────────────
uniform sampler2D BaseSampler;
uniform sampler2D scratchesSampler;
uniform sampler2D GrungeSampler;
uniform sampler2D HoloMaskSampler;
uniform sampler2D HoloSpectrumSampler;
uniform sampler2D AOSampler;
uniform sampler2D ExpSampler;
uniform sampler2D NormalMapSampler;
uniform float g_flUnWearStrength;
uniform float g_flWearAmt;
uniform float g_flGrungeScale;
uniform float g_flWearWidth;
uniform float g_flTintLerpBase;
uniform float g_flWearRemapped;
uniform float g_flPhongBoost;
uniform float g_flPhongAlbedoBoost;
uniform float g_flPhongExponent;
uniform float g_bPhongAlbedoTint;
uniform vec3 g_fvColorTint;
// ─────────────────────────────────────────────────────────────────

#include <alphamap_pars_fragment>
#include <aomap_pars_fragment>
#include <lightmap_pars_fragment>
#include <emissivemap_pars_fragment>
#include <bsdfs>
#include <cube_uv_reflection_fragment>
#include <envmap_common_pars_fragment>
#include <envmap_physical_pars_fragment>
#include <fog_pars_fragment>
#include <lights_pars_begin>
#include <lights_physical_pars_fragment>
#include <shadowmap_pars_fragment>
#include <bumpmap_pars_fragment>
#include <normalmap_pars_fragment> 
#include <roughnessmap_pars_fragment>
#include <metalnessmap_pars_fragment>
#include <logdepthbuf_pars_fragment>
#include <clipping_planes_pars_fragment>

float g_flScratchwidth = 0.02;

#if ( DESATBASETINT == 1 )
vec3 g_desat = vec3(0.299, 0.587, 0.114);
#endif

vec4 getPixelColor() {
    vec4 cOut = texture2D(BaseSampler, vUv);

    #if ( DESATBASETINT == 1 )
    cOut.rgb = mix( vec3(dot(g_desat.rgb, cOut.rgb)).xxx, cOut.rgb, g_flTintLerpBase );
    #endif

    #if ( ALPHAMASK == 1 )
    cOut.a = step( 0.1f, cOut.a );
    cOut.rgb = cOut.a;
    cOut.a = 1.0;
    return cOut;
    #endif

    float flUnWearImportance = g_flUnWearStrength * ( 1.0 - cOut.a );

    cOut.a = step(0.1, cOut.a);

    #if (DECALSTYLE != 2) // non-color-replace logos can still be color tinted by the first color tint value

    #if (DESATBASETINT == 1)
    cOut.rgb = mix( cOut.rgb * g_fvColorTint, cOut.rgb, cOut.g * g_flTintLerpBase );
    #else
    cOut.rgb *= g_fvColorTint;
    #endif

    #endif


    // float3 vWorldPos = i.worldPos;
    // float3 vEyeDir = normalize( g_EyePos - vWorldPos );

    #if ( (DECALSTYLE == 4) || ( DECALSTYLE == 5 ) ) // foil emboss uses normal map
    // ─────────────────────────────────────────────────────────────────
    vec4 vNormalTexel = texture2D( NormalMapSampler, vUv );
    vec3 vTangentSpaceNormal = 2.0 * vNormalTexel.xyz - 1.0;
    // vec3 vWorldNormal = normalize( normalMatrix * normal );
    // vec3 vWorldNormal = normalize( mul( (float3x3)i.tangentSpaceTranspose, vTangentSpaceNormal ) );
    // #if ( DECALSTYLE == 5)
    //     // flatten the normal for anisotropic spec to reduce aliasing
    //     float3 vSpecNormal = normalize( mul( (float3x3)i.tangentSpaceTranspose, lerp( vTangentSpaceNormal, float3( 0.0f, 0.0f, 1.0f ), 0.95f ) ) );
    // #endif
    // ─────────────────────────────────────────────────────────────────



    // ─────────────────────────────────────────────────────────────────

    #else
    // vec3 i_vWorldNormal = inverseTransformDirection(vNormal, viewMatrix);
    // vec3 vWorldNormal = normalize ( i_vWorldNormal );
    vec3 vWorldNormal = normalize ( vNormal );
    #endif

    // ─────────────────────────────────────────────────────────────────

    #if (DECALSTYLE == 2) // color-replace logo
    // cOut.rgb = mix( mix( mix( g_fvColorTint, g_fvColorTint2, cOut.r ), g_fvColorTint3, cOut.g ), g_fvColorTint4, cOut.b );
    #endif

    #if (DECALSTYLE == 3) // hologram
    vec3 fvHoloMask = texture2D(HoloMaskSampler, vUv).rgb;

    // vec2 fvSpectumUV = vec2(fvHoloMask.g, fvHoloMask.b);

    // vec2 fvSpectumUV = vec2(/* mod( */fvHoloMask.g + dot(vViewPosition, vWorldNormal)/* , 1.0) */, fvHoloMask.b);
    vec3 vEyeDir = normalize( vNormal );
    vec2 fvSpectumUV = vec2(mod(fvHoloMask.g + (1.0 - dot(vEyeDir, normalize(vec3( 0.0, 0.0, 1.0 )) )), 1.0), 1.0 - fvHoloMask.b);
    // vec2 fvSpectumUV = vec2( fvHoloMask.g + dot( vViewPosition + vNormal, vec3( 0, 1, 0 ) ), fvHoloMask.b );
    // float3 fvlightdir0 = normalize(cLightInfo[0].pos.xyz - vWorldPos);
    // fvSpectrumUV.x += dot( vViewPosition, fvlightdir0 );

    vec3 fvHoloSpectrumSrc = texture2D(HoloSpectrumSampler, fvSpectumUV).rgb;

    cOut.rgb = mix(cOut.rgb, fvHoloSpectrumSrc, fvHoloMask.r);

    #endif

    // lighting

    // end lighting

    // vec3 linearColor = PixelShaderDoLighting( vWorldPos, vWorldNormal, float3( 0.1f, 0.1f, 0.1f), false, true, i.lightAtten, g_cAmbientCube, NormalizeSampler, NUM_LIGHTS, cLightInfo, false, false, NULL, flCSMShadow );


    // ─── GRUNGE ──────────────────────────────────────────────────────

    // sample ao
    // sticker wear
    vec4 fvAOSrc = texture2D(AOSampler, vUv);//.rbga;
    // vec4(0.0, 1.0, 1.0, 1.0);//
    vec4 fvScratchesSrc = mix(vec4(0.0), texture2D( scratchesSampler, vUv * 0.5), g_flWearAmt);// + vec2(0.5, 0.0) );
    // float4 fvGrungeSrc = texture2D( GrungeSampler, i.vBaseUV_PatternUV.zw * g_flGrungeScale );
    vec4 fvGrungeSrc = texture2D( GrungeSampler, mod(vUv * g_flGrungeScale, vec2(1.0)) );
    float cavity = 1.0 - fvAOSrc.r * fvAOSrc.g * fvScratchesSrc.g;

    cOut.rgb = mix(cOut.rgb, cOut.rgb * fvGrungeSrc.rgb, g_flWearAmt * 0.7);

    float flLocalRemappedWear = g_flWearRemapped - flUnWearImportance;

    float alphaWearPoint = clamp(flLocalRemappedWear - g_flWearWidth, 0.0, 1.0);

    // ─────────────────────────────────────────────────────────────────

    #if (DECALSTYLE == 4)
    //foil embossed labels have hard wear edges
    cOut.a *= step( alphaWearPoint + g_flScratchwidth, cavity );
    // cOut.a *= smoothstep( alphaWearPoint - g_flScratchwidth, alphaWearPoint + g_flScratchwidth, cavity );
    #else
    cOut.a *= smoothstep( alphaWearPoint - g_flScratchwidth, alphaWearPoint + g_flScratchwidth, cavity );
    #endif

    #if ( DECALSTYLE == 1 || DECALSTYLE == 3 ) // paper-backed or holographic (which is also paper-backed)
    float colorWear = smoothstep( flLocalRemappedWear - g_flScratchwidth, flLocalRemappedWear + g_flScratchwidth, cavity );
    cOut.rgb = mix( fvGrungeSrc.rgb, cOut.rgb, colorWear );
    #endif

    #if ( DECALSTYLE == 5 ) // color burn lighting for extra saturation
    // cOut.rgb = mix( cOut.rgb * cOut.rgb * cOut.rgb, cOut.rgb, linearColor );
    #endif

    #if (PHONG == 1)
    vec4 fvSpecularExponent = vec4( g_flPhongExponent, g_bPhongAlbedoTint, 0.0, 1.0 );
    #if (PHONGEXPONENTTEXTURE == 1)
    fvSpecularExponent.xy = texture2D( ExpSampler, vUv ).xy;
    #endif
    // sticker light
    // cOut.rgb *= max( g_flPhongBoost, fvSpecularExponent.g * g_flPhongAlbedoBoost ) * cOut.rgb;
    #endif

    // ─────────────────────────────────────────────────────────────────

    #if (CUBEMAP == 1)
    cOut.rgb += envMapColor;
    #endif

    #if ( THIRDPERSON == 0 )
    //secondary blurred ao
    cOut.rgb *= mix( 1.0, fvAOSrc.b, g_flWearAmt * 0.35 );

    //apply AO
    cOut.rgb *= fvAOSrc.g;
    #endif //THIRDPERSON == 0

    // ─────────────────────────────────────────────────────────────────

    return cOut;
}

void main() {

    #include <clipping_planes_fragment>

    vec4 diffuseColor = vec4( diffuse, opacity );
    ReflectedLight reflectedLight = ReflectedLight( vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ), vec3( 0.0 ) );
    vec3 totalEmissiveRadiance = emissive;

    #include <logdepthbuf_fragment> 

    diffuseColor *= getPixelColor(); 

    #include <roughnessmap_fragment>
    #include <metalnessmap_fragment>
    #include <normal_fragment_begin>
    #if (DECALSTYLE == 4)
    #include <normal_fragment_maps>
    #endif

    // accumulation
    #include <lights_physical_fragment>
    #include <lights_fragment_begin>
    #include <lights_fragment_end>

    vec3 outgoingLight = reflectedLight.directDiffuse + reflectedLight.indirectDiffuse + reflectedLight.directSpecular + reflectedLight.indirectSpecular + totalEmissiveRadiance;

    // this is a stub for the transparency model
    #ifdef TRANSPARENCY
    diffuseColor.a *= saturate( 1. - transparency + linearToRelativeLuminance( reflectedLight.directSpecular + reflectedLight.indirectSpecular ) );
    #endif

    gl_FragColor = vec4( outgoingLight, diffuseColor.a );
}

`;