<template>
    <div ref="viewer"><slot /></div>
</template>

<script>
import Viewer from '@/viewer/lib';

import * as THREE from 'three';
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer.js";
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass.js";
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { FRAGMENT_SHADER_HOLO } from '@/app/three/shaders/sticker.js';

export default {
    props: ['name'],
    data() {return {
        tick: null,
        //
        camera: null,
        scene: null,
        renderer: null,
        mesh: null,
        // load
        manager: null,
        loader: null,
        textureLoader: null,
        composer: null,
        clock: null,
        is_available_render: false,

        loaded: false,
        show_image: false,
        visible: false
    }},
    beforeDestroy() {
        this.destroy();
    },
    computed: {
        COMPUTED () {
            return {
                loaded: !this.loaded,
                show_image: this.show_image,
                visible: this.visible
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
            this.animate();

            setTimeout(() => this.visible = true, 20);
        });
    },
    methods: {
        destroy () {
            cancelAnimationFrame(this.tick);
            this.renderer.domElement.addEventListener('dblclick', null, false); //remove listener to render
            this.scene = null;
            this.camera = null;
        },
        init() {
            this.clock = new THREE.Clock();
            const container = this.$refs.viewer;

            this.camera = new THREE.PerspectiveCamera(5, container.clientWidth / container.clientHeight, 1, 2000);
            this.camera.position.set(20, 1, 20);
            this.camera.lookAt(0, 0, 0);

            this.scene = new THREE.Scene();

            this.drawLight();

            this.renderer = new THREE.WebGLRenderer({
                powerPreference: "high-performance",
                antialias: true,
                alpha: true
            });

            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            container.appendChild(this.renderer.domElement);

            this.composer = new EffectComposer(this.renderer);
            this.composer.addPass(new RenderPass(this.scene, this.camera));

            this.manager = new THREE.LoadingManager();

            this.loader = new GLTFLoader(this.manager);
            this.textureLoader = new THREE.TextureLoader(this.manager);

            this.is_available_render = false;

            const apiOptions = {
                name: this.name//'Sticker | Tyranids Hive Tyrant (Holo)'//this.name
            };

            // if (this.viewer_type === 'arena') {
            //     apiOptions.key = this.modal_context.BOX_KEY;
            // }

            Viewer.getPreview(apiOptions, (err, res) => {
                console.log('-> info', err || !Object.keys(res?.render).length, err, res);

                if (err || !Object.keys(res?.render).length) {
                    this.loaded = true;
                    this.show_image = true;
                }

                if (res) this.loadStickerViewerInventory(res.render);
            });
        },
        drawLight() {
            let pointLight = new THREE.PointLight('#a9edff', 1);
            pointLight.position.set(25, -5, 20);
            this.scene.add(pointLight);

            let pointLight2 = new THREE.PointLight('#ffd690', 0.5);
            pointLight2.position.set(25, 0, 20);
            this.scene.add(pointLight2);

            let pointLight3 = new THREE.DirectionalLight('#cd94cc', 1);
            pointLight3.position.set(25, 30, -20);
            this.scene.add(pointLight3);
        },
        loadStickerViewerInventory(render) {
            const { texture } = render;
            if (!texture) return false;

            // Створюємо новий матеріал MeshStandardMaterial
            // Очікуємо його завантаження, після його оновлюємо код шейдеру матеріалу.
            const sticker = this.getStickerObject(render, { doubleSide: true });

            const image_plane = sticker.children[0];
            image_plane.scale.set(1.8, 1.8, 1.8);
            image_plane.rotation.x = -0.4;
            image_plane.rotation.z = 0.1;
            image_plane.position.y = 0.45;

            // this.$to_rotate = sticker;

            this.scene.add(sticker);

            this.is_available_render = true;

            this.loaded = true;
        },
        getStickerObject(render, options = {}) {
            const { uniforms : params, texture, mask, spectrum, normal } = render;
            const image_holo_material = new THREE.MeshStandardMaterial();
            image_holo_material.onBeforeCompile = (shader) => {
                const uniforms = {
                    BaseSampler: { type: 't', value: this.textureLoader.load(texture) },
                    scratchesSampler: { type: 't', value: this.textureLoader.load('https://media.skin.fans/service/item-viewer/assets/paint_wear.png') },
                    GrungeSampler: { type: 't', value: this.textureLoader.load('https://media.skin.fans/service/item-viewer/assets/sticker_paper.png') },
                    AOSampler: { type: 't', value: this.textureLoader.load('https://media.skin.fans/service/item-viewer/assets/ao_default.png') },
                };

                // Для галографії
                if (params.decalstyle === 3) {
                    uniforms.HoloMaskSampler = { type: 't', value: this.textureLoader.load(mask)};
                    uniforms.HoloSpectrumSampler = { type: 't', value: this.textureLoader.load(spectrum)};
                }

                // Для іншої єбали
                if (params.decalstyle === 4 && normal) {
                    uniforms.NormalMapSampler = { type: 't', value: this.textureLoader.load(normal)};
                }

                // Оновлюємо внутрішні дані стандартного шейдеру, щоб змінити хід його роботи.
                shader.uniforms = Object.assign(shader.uniforms, uniforms);
                shader.fragmentShader = FRAGMENT_SHADER_HOLO;
                shader.needsUpdate = true;

                shader.defines = Object.assign(shader.defines, {
                    PHONGEXPONENTTEXTURE: 0,
                    DECALSTYLE: params.decalstyle || 2,
                    STANDART: true,
                    USE_UV: true,
                    DESATBASETINT: params.desatbasetint > 0 ? 1 : 0,
                    THIRDPERSON: 0,
                    PHONG: params.phong,
                    ALPHAMASK: 0,
                    CUBEMAP: 0,
                    USE_NORMALMAP: true,
                    TANGENTSPACE_NORMALMAP: true
                });

                shader.uniforms.g_flWearAmt = { value: 0 };
                shader.uniforms.g_flWearWidth = { value: 0 };
                shader.uniforms.g_flWearRemapped = { value: 0 };
                shader.uniforms.g_flUnWearStrength = { value: params.unwearstrength };
                shader.uniforms.g_fvColorTint = { value: params.colortint.map(color => color / 255) || [1, 1, 1] };
                shader.uniforms.g_flGrungeScale = { value: params.grungescale };
                shader.uniforms.g_flPhongBoost = { value: params.phongboost };
                shader.uniforms.g_flPhongAlbedoBoost = { value: params.phongalbedoboost };
                shader.uniforms.g_flTintLerpBase = { value: params.desatbasetint };
                shader.uniforms.g_flPhongExponent = { value: params.phongexponent };
                shader.uniforms.g_bPhongAlbedoTint = { value: params.phongalbedotint };
            };

            image_holo_material.transparent = true;

            const image_plane_material = new THREE.MeshBasicMaterial({
                transparent: true,
                map: this.textureLoader.load('https://media.skin.fans/service/item-viewer/assets/card-plane.png'),
                alphaTest: 0.5,
            });

            const image_plane = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1, 1, 1), image_plane_material);
            image_plane.scale.set(2.5, 2.5, 2.5);

            const image_holo = new THREE.Mesh(new THREE.PlaneBufferGeometry(1, 1, 1, 1), image_holo_material);
            image_holo.position.set(0, 0, 0.01);
            image_plane.add(image_holo);

            if (options.doubleSide) {
                image_plane_material.side = THREE.DoubleSide;
                const image_holo_backside = image_holo.clone();
                image_holo_backside.position.set(0, 0, -0.01);
                image_holo_backside.rotation.y = 180 * Math.PI / 180;
                image_plane.add(image_holo_backside);
            }

            const sticker_group = new THREE.Group();
            sticker_group.add(image_plane);

            return sticker_group;
        },
        animate() {
            this.tick = requestAnimationFrame(this.animate);
            const delta = this.clock.getDelta();

            // if (this.$to_rotate) this.$to_rotate.rotation.y += delta;

            if (this.mixer) this.mixer.update(delta); // звернути увагу на це. Це потрібно для анімації
            if (!this.is_available_render) return;

            this.renderer.render(this.scene, this.camera);
            this.composer.render(delta);
        }
    }
}
</script>