<template>
    <div class="banner">
        <div class="banner-content">
            <div class="banner-sf">
            </div>
            <div class="banner-name">{{ name }}</div>
        </div>
        <DefaultModel v-if="!IS_STICKER" :name="name" :item_name="ITEM_NAME" class="banner-model">
            <slot />
        </DefaultModel>
        <StickerModel v-else :name="name" :item_name="ITEM_NAME"  class="banner-model"><slot /></StickerModel>
    </div>
</template>

<script>
import DefaultModel from "@/viewer/view/default-model";
import StickerModel from "@/viewer/view/sticker-model";

export default {
    props: ['name'],
    // eslint-disable-next-line vue/multi-word-component-names
    name: 'banner',
    components: {
        DefaultModel, StickerModel
    },

    data () {return {
    }},

    computed: {
        IS_STICKER () {
            return this.name?.includes('Sticker');
        },
        ITEM_NAME () {
            return this.getItemName(this.name);
        }
    },

    methods: {
        getItemName (name) {
            return name
                ?.split('|')[0]
                ?.replace('★', '')
                ?.replace('StatTrak™', '')
                ?.trim() || '';
        }
    }
}
</script>

<style scoped>
.banner {
    position: relative;
    display: flex;
    margin: auto;
    width: 1000px;
    height: 500px;
    background: linear-gradient(45deg, #000 0%, #fff 100%);
}

.banner > .banner-model {
    width: 100%;
    height: 100%;
}

.banner-content {
    position: absolute;
    inset: 0;
}
</style>