<template>
    <div ref="viewer">
        <slot />
    </div>
</template>

<script>
import Viewer from '@/viewer/lib';

import * as THREE from 'three';
import { EffectComposer, RenderPass } from "postprocessing";
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';

export default {
    props: ['name', 'item_name'],
    data () {return {
        tick: null,
        //
        camera: null,
        scene: null,
        renderer: null,
        mesh: null,
        // load
        manager: null,
        controls: null,
        textureLoader: null,
        composer: null,
        clock: null,

        loaded: false,
        show_image: false,
        visible: false,
    }},
    beforeDestroy() {
        this.destroy();
    },
    computed: {
        COMPUTED () {
            return {
                loaded: !this.loaded,
                show_image: this.show_image,
                visible: this.visible,
            }
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.init();
            // this.animate();

            setTimeout(() => this.visible = true, 20);
        });
    },
    methods: {
        init() {
            this.clock = new THREE.Clock();

            let container = this.$refs.viewer;

            this.camera = new THREE.PerspectiveCamera(35, container.clientWidth / container.clientHeight, 1, 2000);
            // this.camera.position.x = 5;
            // this.camera.position.z = 100;

            this.scene = new THREE.Scene();

            //

            this.renderer = new THREE.WebGLRenderer({
                powerPreference: "high-performance",
                antialias: true,
                alpha: true
            });

            this.renderer.setPixelRatio(window.devicePixelRatio);
            this.renderer.setSize(container.clientWidth, container.clientHeight);
            container.appendChild(this.renderer.domElement);

            this.composer = new EffectComposer(this.renderer, {
                frameBufferType: THREE.HalfFloatType
            });
            this.composer.addPass(new RenderPass(this.scene, this.camera));

            // ------------------------------------
            // CONTROL
            // ------------------------------------

            this.camera.position.set(45, -5, 45);
            this.camera.lookAt(-10,0,0);

            this.drawLight();

            const apiOptions = {
                name: this.name
            };

            Viewer.getPreview(apiOptions, (err, res) => {

                console.log(res, 'res')

                if (err || !Object.keys(res?.render).length) {
                    this.loaded = true;
                }

                if (!res?.render?.object) this.show_image = true;

                if (res) {
                    this.load(res);
                }
            });
        },
        drawLight () {
            const pointLight = new THREE.PointLight('#ae4f6a', 2);
            pointLight.position.set( 20, 0, -20 );
            this.scene.add(pointLight);

            const pointLight2 = new THREE.PointLight('#5afff1', 1);
            pointLight2.position.set( 20, -5, 20 );
            this.scene.add(pointLight2);

            const pointLight3 = new THREE.DirectionalLight('#cfba8c', 0.8);
            pointLight3.position.set( 20, 30, -20 );
            this.scene.add(pointLight3);

            const pointLight4 = new THREE.DirectionalLight('#fdfdff', 3);
            pointLight4.position.set( -20, 30, -20 );
            this.scene.add(pointLight4);
        },
        animate () {
            // this.controls.update();
            this.renderer.render(this.scene, this.camera);
            this.composer.render(this.clock.getDelta());
            document.querySelector('#app').classList.add('loaded');
        },
        load(data) {
            this.manager = new THREE.LoadingManager();
            this.manager.onProgress = (item, loaded, total) => {
                console.log('onProgress', item, loaded, total);
            };

            // Загрузка модели (меша)
            this.loadModel(data);
        },
        destroy () {
            cancelAnimationFrame(this.tick);
            this.renderer.domElement.addEventListener('dblclick', null, false); //remove listener to render
            this.scene = null;
            this.camera = null;
            // this.controls = null;
        },

        loadModel(data) {
            let material = new THREE.MeshNormalMaterial(), textureSight;
            let _this = this;
            let salt = Math.random() * 100000;

            this.loader = new OBJLoader(this.manager);
            this.textureLoader = new THREE.TextureLoader(this.manager);

            if (typeof data !== 'object') return;

            let texture = this.textureLoader.load(data.render.texture + `?${salt}`, object => {
                console.log("Loaded!", data.render.texture)
            }, {}, error => {
                _this.show_image = true;
            });

            if (data.render.texture_sight) {
                textureSight = this.textureLoader.load(data.render.texture_sight + `?${salt}`, object => {
                    console.log("Loaded!", data.render.texture_sight)
                });
            }

            let normal = data.render.shader ? this.textureLoader.load(data.render.shader.normal + `?${salt}`, object => {
                console.log("Loaded!", data.render.shader.normal + `?${salt}`)
            }) : null;

            this.loader.load(data.render.object + `?${salt}`, object => {

                _this.loaded = true;

                object.traverse(child => {
                    if (child instanceof THREE.Mesh) {

                        console.log('-> name', child.name);

                        switch (child.name) {
                            case 'lens':
                            case 'lens_2':
                                setBlackMaterial(child);
                                break;

                            case 'model':
                            case 'left':
                            case 'right':
                            case 'model1':
                            case 'v_smg_mac10_mac10_model':
                            case 'model_2':
                            case 'model_3':
                                texture.wrapS = THREE.RepeatWrapping;
                                texture.wrapT = THREE.RepeatWrapping;
                                texture.flipY = 1;

                                const material = new THREE.MeshPhongMaterial({
                                    // color: new THREE.Color('#000000'),
                                    // transparent: true,
                                    // opacity: 1
                                })
                                child.material = material;

                                child.material.map = texture;

                                console.log('set texture', child.name, texture);

                                if (normal) {
                                    normal.wrapS = THREE.RepeatWrapping;
                                    normal.wrapT = THREE.RepeatWrapping;
                                    normal.flipY = 1;

                                    child.material.normalMap = normal;
                                }
                                break;
                            case 'scope':
                            case 'scope_2':
                                child.material.map = textureSight;
                                break;
                            default:
                                child.visible = false;
                                break;
                        }

                        if (child.name === 'model_2' && data.item.details.gun_type === 'AUG') {
                            child.material.map = textureSight;
                        }

                        if (child.name === 'model' && this.item_name === 'Shadow Daggers') {
                            child.position.x = -1.5;
                            child.position.y = 1.5;
                            child.position.z = -1.4;

                            child.rotation.x = -315 * Math.PI / 180;
                            child.rotation.y = 0;
                            child.rotation.z = 180 * Math.PI / 180;
                        }

                        if (child.name === 'model_2' && this.item_name === 'Shadow Daggers') {
                            child.position.x = 1.5;
                            child.position.y = 1.5;
                            child.position.z = 1.4;

                            child.rotation.x = 135 * Math.PI / 180;
                            child.rotation.y = 0;
                            child.rotation.z = 180 * Math.PI / 180;
                        }
                    }
                });

                const map = Viewer.SCENE_ITEM_MAP[this.item_name];

                if (map && Object.keys(map).length > 0) {
                    if (map?.position) object.position.set(...map.position);
                    object.scale.set(map.scale, map.scale, map.scale);
                    if (map?.rotate) object.rotation.set(...map.rotate);
                }

                if (this.scene) this.scene.add(object);

                this.animate();
            });

            function setBlackMaterial(mesh) {
                mesh.material = new THREE.MeshStandardMaterial({ color: new THREE.Color('#000000') })
            }
        }
    }
}
</script>