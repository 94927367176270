import axios from 'axios';

const GLOVES_SCHEMA = {
    position: [0, 2, 0],
    rotate: [0, 0, 0],
    scale: 2,
}

const SCENE_ITEM_MAP = {
    'M4A1': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'Desert Eagle': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 2,
    },
    'Dual Berettas': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1.5,
    },
    'M4A4': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'UMP-45': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'MP7': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'FAMAS': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'PP-Bizon': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1.25,
    },
    'Tec-9': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1.5,
    },
    'USP-S': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1.5,
    },
    'P2000': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 2,
    },
    'Five-SeveN': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 2,
    },
    'P250': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 2,
    },
    'AWP': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'AK-47': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'SG 553': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'Galil AR': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 1,
    },
    'Glock-18': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 2,
    },
    'M4A1-S': {
        position: [0, 2, 0],
        rotate: [0, 0, 0],
        scale: 0.75,
    },
    'Shadow Daggers': {
        // position: [0, 2, 0],
        // rotate: [45, 0, 180 * Math.PI / 180],
        scale: 2.25,
    },
    'M9 Bayonet': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Talon Knife': {
        position: [0, 4, 0],
        rotate: [-(30 * Math.PI / 180), 0, 0],
        scale: 2.2,
    },
    'Bowie Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Survival Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Flip Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Classic Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Bayonet': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Navaja Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 2.2,
    },
    'Falchion Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Stiletto Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Ursus Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Butterfly Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'MAC-10': {
        position: [0, 3, 0],
        rotate: [0, 0, 0],
        scale: 1.25,
    },
    'Huntsman Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Nomad Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Karambit': {
        position: [0, 4, 0],
        rotate: [200 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Gut Knife': {
        position: [0, 4, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75,
    },
    'Paracord Knife': {
        position: [0, 2, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75
    },
    'Skeleton Knife': {
        position: [0, 2, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75
    },
    'Bloodhound Gloves': {
        position: [0, 2, 0],
        rotate: [150 * Math.PI / 180, 0, 180 * Math.PI / 180],
        scale: 1.75
    },
    'Broken Fang Gloves': GLOVES_SCHEMA,
    'Driver Gloves': GLOVES_SCHEMA,
    'Hand Wraps': GLOVES_SCHEMA,
    'Hydra Gloves': GLOVES_SCHEMA,
    'Moto Gloves': GLOVES_SCHEMA,
    'Specialist Gloves': GLOVES_SCHEMA,
    'Sport Gloves': GLOVES_SCHEMA,
}

function getPreview({ name, key }, callback) {
    let params = { ...{ name } };

    axios.get('https://2e759ac871-api.skin.fans/method/arena.item', { params, withCredentials: true })
        .then(response => {
            callback && callback(null, response.data?.response)
        })
        .catch(error => {
            callback && callback(error.response, null)
        });
}

export default { GLOVES_SCHEMA, SCENE_ITEM_MAP, getPreview }