<template>
    <div id="app">
        <Fonts/>
        <Banner name="AK-47 | Point Disarray (Battle-Scarred)"></Banner>
<!--              <Banner name="Sticker | Complexity Gaming | Paris 2023"></Banner>-->
    </div>
</template>

<script>
import Banner from './components/banner.vue'
import Fonts from "@/app/fonts";

export default {
    name: 'App',
    components: {
        Banner, Fonts
    },
    data () {return {
        loaded_count: 0,
        resources: [
            'https://media.skin.fans/m/root/fonts/DINPro-CondensedRegular.woff',
            'https://media.skin.fans/m/root/fonts/DINPro-CondensedMedium.woff',
            'https://media.skin.fans/m/root/fonts/DINPro-CondensedBold.woff',
            'https://media.skin.fans/m/root/fonts/DINPro.woff',
            'https://media.skin.fans/m/root/fonts/DINPro-Medium.woff',
            'https://media.skin.fans/m/root/fonts/DINPro-Bold.woff',
            'https://media.skin.fans/m/root/images/header/sf-logo.png',
        ],
    }},

    mounted() {
        this.$cookie.set('hostname', window.location.hostname);
    },

    computed: {
        LOADED () {
            return this.resources.length === this.loaded_count;
        },
    }
}
</script>

<style>
body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#app {
    display: flex;
    flex-direction: column;
    height: 100vh;
    font-family: 'DIN Pro';
    font-weight: 400;
    background-color: #272c34;
}
</style>
